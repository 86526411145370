<template>
    <div id="triggersHours">
        <div>
            <div v-for="(tabHour, index) in tabHours" :key="index" class="d-flex align-center">
                <div>
                    <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormBeginHours") }}</p>
                    <v-select
                        v-model="tabHour.start"
                        :items="getHours(0)"
                        :rules="[rules.notEmpty]"
                        class="mr-5 rounded-lg custom-input custom-select"
                        dense
                        filled
                        required
                        @change="updatedHourEvent()"
                    ></v-select>
                </div>

                <div>
                    <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormEndHours") }}</p>
                    <v-select
                        v-model="tabHour.end"
                        :disabled="isNull(tabHour.start)"
                        :items="getHours(tabHour.start)"
                        :rules="[rules.notEmpty]"
                        class="mr-5 rounded-lg custom-input custom-select"
                        dense
                        filled
                        required
                        @change="updatedHourEvent()"
                    ></v-select>
                </div>
                <v-btn color="blue-grey darken-2" icon @click="removePiece(index)">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </div>
            <div class="d-flex align mb-4 align-center">
                <v-btn color="primary" icon large @click="addPiece">
                    <v-icon large>mdi-plus-circle</v-icon>
                </v-btn>
                <i class="text-body-2">{{ $t("addTriggersHours") }}</i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TriggersHours",
    props: ['getHours', 'activeCampaignHours'],
    data: (vm) => {
        return {
            tabHours: [],
            rules: {
                hour: value => value >= 0 && value <= 23 || vm.$t('triggersFormHourError'),
                notEmpty: value => vm.isNotNull(value) ? true : vm.$t('emptyFieldError'),
            },
        }
    },
    created() {
        this.tabHours = [...this.activeCampaignHours]
        if (this.isNull(this.tabHours)) {
            this.addPiece()
        }
    },
    methods: {
        addPiece() {
            this.tabHours.push({
                start: null,
                end: null
            })
        },
        removePiece(index) {
            let myIndex = index
            if (myIndex !== -1 && myIndex !== 0) {
                this.tabHours.splice(myIndex, 1);
            }
        },
        updatedHourEvent() {
            this.$emit("isUpdatedStartHour", this.tabHours)
        }
    }

}
</script>
<style scoped>
.custom-input >>> .v-input__slot {
    margin: 0 !important;
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}

.custom-input >>> .v-input__slot::after {
    border: none !important;
}

.custom-input >>> .v-input__slot {
    border: none !important;
}

.number-input >>> input::-webkit-outer-spin-button,
.number-input >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-input >>> input[type=number] {
    -moz-appearance: textfield;
}

.custom-select {
    min-width: 276px;
}
</style>
