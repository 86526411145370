const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function add(name){
    let path = "/scenario/add"

    let params = new URLSearchParams();
    params.append("name", name);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function getAllScenarios(){
    let path = "/scenario/get/all"

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}

export function remove(scenarioId){
    let path = "/scenario/remove/by/id"

    let params = new URLSearchParams();
    params.append("scenarioId", scenarioId);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function getById(scenarioId){
    let path = `/scenario/get/by/id?scenarioId=${scenarioId}`

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}
