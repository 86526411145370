<template>
    <div class="manage-campaign">
        <div class="d-flex flex-column manage-campaign-header">
            <p class="d-flex align-center ma-0 text-h4 primary--text font-weight-bold">
                <a @click="$router.push({name: 'Campaigns'})">{{$t("manageCampaignTitle")}}</a>
                <span class="mx-3">></span>
                <span class="ma-0 text-h6 primary--text font-weight-light" v-if="isNull(campaign)">{{$t("manageCampaignTitleCreateCampaign")}}</span>
                <span class="ma-0 text-h6 primary--text font-weight-light" v-else>{{$t("manageCampaignTitleUpdateCampaign")}}</span>
                <template v-if="!isNull(campaign)">
                    <span class="mx-3 font-weight-light">></span>
                    <span class="ma-0 text-h6 primary--text font-weight-light">{{campaign.name}}</span>
                </template>
            </p>
        </div>
        <v-stepper class="pa-3" elevation="0" v-model="step">
            <v-stepper-header class="my-1 elevation-0">
                <v-stepper-step :complete="step > 1" step="1" editable>
                    {{$t("manageCampaignInfoStep")}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2" editable>
                    {{$t("manageCampaignTriggersStep")}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" editable>
                    {{$t("manageCampaignScenarioLinkStep")}}
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1" editable>
                    <CampaignInfoForm @campaignInfoSaved="goToTriggers" @back="goBack()" :campaign="campaign"/>
                </v-stepper-content>

                <v-stepper-content step="2" editable>
                    <CampaignTriggersForm @campaignTriggersSaved="gotToScenarioLink" @back="goBack()" :campaign="campaign"/>
                </v-stepper-content>

                <v-stepper-content step="3" editable>
                    <CampaignScenarioLink @back="goBack()" :campaign="campaign"/>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
    import CampaignTriggersForm from "@/components/SmartEngage/CampaignTriggersForm"
    import CampaignInfoForm from "@/components/SmartEngage/CampaignInfoForm"
    import CampaignScenarioLink from "@/components/SmartEngage/CampaignScenarioLink"
    export default {
        components: {
            CampaignTriggersForm,
            CampaignInfoForm,
            CampaignScenarioLink,
        },
        data() {
            return {
                step: 1,
                campaign: null
            }
        },
        created() {
            this.updatePageInfo(this.$route.meta)
            if(this.isNotNull(this.$route.params.campaign)){
                this.campaign = this.$route.params.campaign
                sessionStorage.setItem("SMART_ENGAGE_CAMPAIGN", JSON.stringify(this.campaign))
            }else if(this.isNotNull(sessionStorage.getItem("SMART_ENGAGE_CAMPAIGN"))){
                this.campaign = JSON.parse(sessionStorage.getItem("SMART_ENGAGE_CAMPAIGN"))
            }
        },
        methods: {
            goBack(){
                this.step -= 1
            },
            goToTriggers(campaignId) {
                if(this.isNotNull(campaignId)){
                    this.campaign = {
                        id: campaignId
                    }
                }
                this.step = 2
            },
            gotToScenarioLink() {
                this.step = 3
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
