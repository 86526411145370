<template>
    <div class="campaign-scenario-link-wrapper">
        <div class="my-4 d-flex flex-column align-start input-group full-width">
            <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("campaignScenarioLinkTitle") }}</p>
            <v-select
                v-model="selectedScenario"
                :items="scenarios"
                class="full-width rounded-lg custom-input"
                dense
                filled
                hide-details
                item-text="name"
                return-object
            ></v-select>
        </div>


        <div v-if="shouldCreateNewScenario" class="d-flex flex-column input-group">
            <v-divider class="mx-5 my-5"></v-divider>
            <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("campaignScenarioLinkNewScenarioNameLabel") }}</p>
            <v-text-field
                v-model="newScenarioName"
                class="custom-input rounded-lg font-weight-bold text-body-2"
                dense
                filled
                hide-details
                @click:append-outer="addScenario"
            ></v-text-field>
        </div>

        <div class="my-2 d-flex align-center full-width">
            <v-spacer></v-spacer>
            <CustomButton :loading="loading" @click="handleAddLinkClick()">{{ $t(btnLabel) }}</CustomButton>
        </div>
    </div>
</template>

<script>
const ScenarioService = require("@/services/ScenarioService");
const CampaignService = require("@/services/CampaignService");
export default {
    name: "CampaingScenarioLink",
    props: ['campaign'],
    data: () => {
        return {
            scenarios: [],
            selectedScenario: null,
            newScenarioName: null,
            loading: false
        }
    },
    async created() {
        this.updatePageInfo(this.$route.meta)
        await this.getScenarios()
        if (this.isNotNull(this.campaign?.scenario)) {
            this.selectedScenario = this.scenarios.find(scenario => scenario.id === this.campaign.scenario.id)
        }
    },
    computed: {
        shouldCreateNewScenario() {
            return this.selectedScenario != null && this.selectedScenario.action == "CREATE"
        },
        btnLabel() {
            return this.shouldCreateNewScenario ? "campaignScenarioLinkCreateNewBtnLabel" : "campaignScenarioLinkBtnLabel"
        }
    },
    methods: {
        getScenarios() {
            return new Promise(resolve => {
                ScenarioService.getAllScenarios().then(({status, data}) => {
                    if (status === 200 && this.isNotNull(data)) {
                        this.scenarios = [{id: -1, name: this.$t("campaignScenarioLinkAddNewScenarioSelectLabel"), action: "CREATE"}, ...data]
                        resolve()
                    }
                })
            })
        },
        addScenario() {
            if (this.isNotNull(this.newScenarioName)) {
                this.loading = true

                ScenarioService.add(this.newScenarioName).then(async ({status, data}) => {
                    if (status === 200 && !data.error) {
                        await this.getScenarios()
                        this.selectedScenario = this.scenarios.find(scenario => scenario.id.toString() === data.scenarioId)
                        this.loading = false
                        this.linkScenario(true)
                    }
                })
            }
        },
        handleAddLinkClick() {
            if (this.shouldCreateNewScenario) {
                this.addScenario()
            } else {
                this.linkScenario()
            }
        },
        linkScenario(newScenario = false) {
            CampaignService.setScenario(this.campaign.id, this.selectedScenario.id).then(({status, data}) => {
                if (status === 200 && !data.error) {
                    if (newScenario) {
                        this.$router.push({name: 'Screens', query: {'scenarioId': this.selectedScenario.id}})
                    } else {
                        this.$router.push({name: 'Campaigns'})
                    }
                }
            })
        }
    },
}
</script>
<style scoped>
.custom-input >>> .v-input__slot {
    margin: 0 !important;
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}

.custom-input >>> .v-input__slot::after {
    border: none !important;
}

.custom-input >>> .v-input__slot {
    border: none !important;
}
</style>
