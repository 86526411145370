<template>
    <div v-if="isNotNull(campaign)" class="triggers-form-wrapper">
        <v-form ref="triggersFormForm" v-model="valid" class="d-flex flex-column mx-2" lazy-validation>
            <v-row>
                <v-col cols="3">
                    <v-menu v-model="showStartDatePicker" :close-on-content-click="false" :nudge-right="40"
                            min-width="auto" offset-y transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex flex-column align-start input-group full-width">
                                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormBeginDate") }}</p>
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.notEmpty]"
                                    :value="startDateFormatted"
                                    append-icon="mdi-calendar"
                                    class="full-width rounded-lg custom-input"
                                    dense
                                    filled
                                    readonly
                                    required
                                ></v-text-field>
                            </div>

                        </template>
                        <v-date-picker
                            v-model="startDate"
                            first-day-of-week="1"
                            @input="showStartDatePicker = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="3">

                    <v-menu v-model="showEndDatePicker" :close-on-content-click="false" :nudge-right="40"
                            min-width="auto" offset-y transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex flex-column align-start input-group full-width">
                                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormEndDate") }}</p>
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.notEmpty]"
                                    :value="endDateFormatted"
                                    append-icon="mdi-calendar"
                                    class="full-width rounded-lg custom-input"
                                    dense
                                    filled
                                    readonly
                                    required
                                ></v-text-field>
                            </div>
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            :min="startDate"
                            @input="showEndDatePicker = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <div class="my-2 d-flex flex-column align-start input-group full-width">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormDays") }}</p>
                <div class="mb-2 d-flex align-center">
                    <v-chip :class="{'darkGrey--text': !daySelected(availableDays[0]), 'white--text': daySelected(availableDays[0])}" :outlined="!daySelected(availableDays[0])"
                            class="mr-2 rounded-lg text-body-1"
                            color="primary" label @click="updateDays(availableDays[0])">{{ $t("days.monday") }}
                    </v-chip>
                    <v-chip :class="{'darkGrey--text': !daySelected(availableDays[1]), 'white--text': daySelected(availableDays[1])}" :outlined="!daySelected(availableDays[1])"
                            class="mx-2 rounded-lg text-body-1"
                            color="primary" label @click="updateDays(availableDays[1])">{{ $t("days.tuesday") }}
                    </v-chip>
                    <v-chip :class="{'darkGrey--text': !daySelected(availableDays[2]), 'white--text': daySelected(availableDays[2])}" :outlined="!daySelected(availableDays[2])"
                            class="mx-2 rounded-lg text-body-1"
                            color="primary" label @click="updateDays(availableDays[2])">{{ $t("days.wednesday") }}
                    </v-chip>
                    <v-chip :class="{'darkGrey--text': !daySelected(availableDays[3]), 'white--text': daySelected(availableDays[3])}" :outlined="!daySelected(availableDays[3])"
                            class="mx-2 rounded-lg text-body-1"
                            color="primary" label @click="updateDays(availableDays[3])">{{ $t("days.thursday") }}
                    </v-chip>
                    <v-chip :class="{'darkGrey--text': !daySelected(availableDays[4]), 'white--text': daySelected(availableDays[4])}" :outlined="!daySelected(availableDays[4])"
                            class="mx-2 rounded-lg text-body-1"
                            color="primary" label @click="updateDays(availableDays[4])">{{ $t("days.friday") }}
                    </v-chip>
                    <v-chip :class="{'darkGrey--text': !daySelected(availableDays[5]), 'white--text': daySelected(availableDays[5])}" :outlined="!daySelected(availableDays[5])"
                            class="mx-2 rounded-lg text-body-1"
                            color="primary" label @click="updateDays(availableDays[5])">{{ $t("days.saturday") }}
                    </v-chip>
                    <v-chip :class="{'darkGrey--text': !daySelected(availableDays[6]), 'white--text': daySelected(availableDays[6])}" :outlined="!daySelected(availableDays[6])"
                            class="mx-2 rounded-lg text-body-1"
                            color="primary" label @click="updateDays(availableDays[6])">{{ $t("days.sunday") }}
                    </v-chip>
                </div>
            </div>

            <div class="my-1 d-flex flex-column align-start input-group full-width">
                <div class="d-flex align-center">
                    <div class="mb-2">
                        <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormHours") }}</p>
                        <p class="mb-1 darkGrey--text text-body-2 font-weight-regular">{{ $t("triggersFormHoursExplain") }}</p>
                    </div>
                </div>
                <div>
                    <TriggersHours :activeCampaignHours="activeCampaignHours" :getHours="getHours" @isUpdatedStartHour="updatedHour"/>
                </div>
            </div>

            <div class="my-1 d-flex flex-column align-stretch">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggerSwitchPayment") }}</p>
                <v-radio-group row v-model="radios">
                    <v-radio value="beforePayment">
                        <template v-slot:label>
                        <div>{{ $t("triggerBefore") }}</div>
                        </template>
                    </v-radio>
                    <v-radio value="afterPayment">
                        <template v-slot:label>
                        <div>{{ $t("triggerAfter") }}</div>
                        </template>
                    </v-radio>
                    <v-radio value="cashRegister">
                        <template v-slot:label>
                        <div>{{ $t("triggerCashRegister") }}</div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>

            <div class="my-1 d-flex align-stretch">
                <div class="mr-6 d-flex flex-column align-start input-group">
                    <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormMinAmountLabel") }}</p>
                    <div class="d-flex align-center">
                        <v-text-field
                            v-model="minAmount"
                            :rules="[rules.notEmpty]"
                            append-icon="mdi-currency-eur"
                            class="rounded-lg custom-input number-input"
                            dense
                            filled
                            required
                            type="number"
                        ></v-text-field>
                    </div>
                </div>
                <div class="mr-3 d-flex flex-column align-start input-group">
                    <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormMaxAmountLabel") }}</p>
                    <div class="d-flex align-center">
                        <v-text-field
                            v-model="maxAmount"
                            :rules="[rules.notEmpty, rules.max]"
                            append-icon="mdi-currency-eur"
                            class="rounded-lg custom-input number-input"
                            dense
                            filled
                            required
                            type="number"
                        ></v-text-field>
                    </div>
                </div>
            </div>

            <div class="my-2 d-flex align-stretch">
                <div class="mr-3 d-flex flex-column align-start input-group">
                    <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormLoyaltyLabel") }}</p>
                    <div class="d-flex align-center">
                        <v-select
                            v-model="loyalty"
                            :items="$t('triggersFormLoyalty')"
                            :rules="[rules.notEmpty]"
                            class="rounded-lg custom-input custom-select"
                            dense
                            filled
                            item-text="value"
                            item-value="key"
                            required
                        ></v-select>
                    </div>
                </div>
                <div class="mx-3 d-flex flex-column align-start input-group">
                    <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormStoreLabel") }}</p>
                    <div class="d-flex align-center">
                        <v-select
                            v-model="store"
                            :items="$t('triggersFormStore')"
                            :rules="[rules.notEmpty]"
                            class="rounded-lg custom-input custom-select"
                            dense
                            filled
                            item-text="value"
                            item-value="key"
                            multiple
                            required
                        >
                            <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0">{{ item.value }}</span>
                                <span v-if="index > 0 && index < 3">, {{ item.value }}</span>
                                <span v-if="index === 3" class="grey--text text-caption">
                                  , (+{{ store.length - 3 }} {{$tc('other', store.length - 3)}})
                                </span>
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @click="toggleStore"
                                    @mousedown.prevent
                                >
                                    <v-list-item-action>
                                        <v-icon :color="store.length > 0 ? 'indigo darken-4' : ''">
                                            {{ iconStore }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t('triggersSelectAllStore') }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mb-2"></v-divider>
                            </template>

                        </v-select>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="d-flex align-center full-width">
            <CustomButton outlined @click="$emit('back')">{{ $t("triggersFormBackBtnLabel") }}</CustomButton>
            <v-spacer></v-spacer>
            <CustomButton @click="triggersForm">{{ $t("triggersFormValidBtnLabel") }}</CustomButton>
        </div>
    </div>
    <div v-else>
        {{ $t("triggersFormNoCampaignIdLabel") }}
    </div>
</template>

<script>
const CampaignService = require("@/services/CampaignService");
import TriggersHours from "@/components/SmartEngage/Utils/TriggersHours";

export default {
    name: 'CampaignTriggersForm',
    props: ['campaign'],
    components: {TriggersHours},
    data: (vm) => {
        return {
            availableDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            startDate: null,
            endDate: null,
            activeCampaignHours: [],
            minAmount: null,
            maxAmount: null,
            nbProducts: null,
            needLoyalty: false,
            days: [],
            loyalty: null,
            store: [],
            radios: "afterPayment",

            showStartDatePicker: false,
            showEndDatePicker: false,
            showStartHourPicker: false,
            showEndHourPicker: false,
            valid: true,
            rules: {
                hour: value => value >= 0 && value <= 23 || vm.$t('triggersFormHourError'),
                notEmpty: value => vm.isNotNull(value) ? true : vm.$t('emptyFieldError'),
                max() {
                    if (vm.maxAmount && vm.minAmount && parseFloat(vm.maxAmount) <= parseFloat(vm.minAmount)) {
                        return vm.$t('valueIsgreaterThanMinAmount');
                    }
                    return true
                },
            },
        }
    },
    computed: {
        iconStore() {
            if (this.selectAllStore) return 'mdi-close-box'
            if (this.selectSomeStore) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selectAllStore() {
            return this.store.length === this.$t('triggersFormStore').length
        },
        selectSomeStore() {
            return this.store.length > 0 && !this.selectAllStore
        },
        startDateFormatted() {
            return this.formatDatePickerToDateLocale(this.startDate)
        },
        startDateFormattedEn() {
            return this.formatDatePickerToDateLocale(this.startDate, "en")
        },
        endDateFormatted() {
            return this.formatDatePickerToDateLocale(this.endDate)
        },
        endDateFormattedEn() {
            return this.formatDatePickerToDateLocale(this.endDate, "en")
        },
        daySelected() {
            return (day) => {
                let index = this.days.findIndex(tempDay => tempDay === day)
                return index > -1
            };
        },
        needLoyaltyValue() {
            switch (this.loyalty) {
                case "UNKNOWN_CUSTOMERS":
                    return "0";
                case "KNOWN_CUSTOMERS":
                    return "1";
                case "ALL_CUSTOMERS":
                    return "2";
                default:
                    return null;
            }
        }
    },
    created() {
        console.log(this.campaign);
        this.startDate = this.formatDateLocaleForPicker(this.campaign?.triggerList?.find(trigger => trigger.name === "DATE_START")?.value, "en") || null
        this.endDate = this.formatDateLocaleForPicker(this.campaign?.triggerList?.find(trigger => trigger.name === "DATE_END")?.value, "en") || null
        // this.startHour = this.campaign?.triggerList?.find(trigger => trigger.name === "HOUR_START")?.value || null
        // this.endHour = this.campaign?.triggerList?.find(trigger => trigger.name === "HOUR_END")?.value || null
        this.activeCampaignHours = this.campaign?.triggerList?.find(trigger => trigger.name === "HOURS")?.value || []
        this.radios = this.campaign?.triggerList?.find(trigger => trigger.name === "LAUNCH_MOMENT")?.value || "afterPayment"
        this.minAmount = this.campaign?.triggerList?.find(trigger => trigger.name === "MIN_AMOUNT")?.value || null
        this.maxAmount = this.campaign?.triggerList?.find(trigger => trigger.name === "MAX_AMOUNT")?.value || null
        this.nbProducts = this.campaign?.triggerList?.find(trigger => trigger.name === "NB_PRODUCTS")?.value || null
        this.days = this.campaign?.triggerList?.find(trigger => trigger.name === "DAYS")?.value?.split(', ') || []
        this.store = this.campaign?.triggerList?.find(trigger => trigger.name === "STORE")?.value?.split(', ') || []

        let needLoyalty = this.campaign?.triggerList?.find(trigger => trigger.name === "NEED_LOYALTY")?.value
        switch (needLoyalty) {
            case "0":
                this.loyalty = "UNKNOWN_CUSTOMERS";
                break;
            case "1":
                this.loyalty = "KNOWN_CUSTOMERS";
                break;
            case "2":
                this.loyalty = "ALL_CUSTOMERS";
                break;
            default:
                this.loyalty = null;
        }
        this.activeCampaignHours = JSON.parse(this.activeCampaignHours)
    },
    methods: {
        toggleStore() {
            this.$nextTick(() => {
                if (this.selectAllStore) {
                    this.store = []
                } else {
                    let tabTriggers = this.$t('triggersFormStore')
                    this.store = []
                    for (let i = 0; i < tabTriggers.length; i++) {
                        this.store.push(tabTriggers[i].key)
                    }
                }
            })
        },
        updatedHour(tabHours) {
            this.activeCampaignHours = tabHours
        },

        updateDays(day) {
            let index = this.days.findIndex(tempDay => tempDay === day)
            if (index > -1) {
                this.days.splice(index, 1)
            } else {
                this.days.push(day)
            }
        },
        triggersForm() {
            if (this.$refs.triggersFormForm.validate()) {
                let triggers = [{
                    "name": "DATE_START",
                    "value": this.startDateFormattedEn
                },
                    {
                        "name": "DATE_END",
                        "value": this.endDateFormattedEn
                    },
                    /*{
                        "name": "HOUR_START",
                        "value": this.startHour.toString()
                    },
                    {
                        "name": "HOUR_END",
                        "value": this.endHour.toString()
                    },*/
                    {
                        "name": "LAUNCH_MOMENT",
                        "value": this.radios
                    },
                    {
                        "name": "HOURS",
                        "value": JSON.stringify(this.activeCampaignHours)
                    },
                    {
                        "name": "NB_PRODUCTS",
                        "value": this.nbProducts
                    },
                    {
                        "name": "MIN_AMOUNT",
                        "value": this.minAmount
                    },
                    {
                        "name": "MAX_AMOUNT",
                        "value": this.maxAmount
                    },
                    {
                        "name": "DAYS",
                        "value": this.days.reduce((result, current, index) => index === 0 ? current : result + ", " + current, "")
                    },
                    {
                        "name": "NEED_LOYALTY",
                        "value": this.needLoyaltyValue
                    },
                    {
                        "name": "STORE",
                        "value": this.store.reduce((result, current, index) => index === 0 ? current : result + ", " + current, "")
                    },
                ]

                CampaignService.manageTriggers(this.campaign.id, JSON.stringify(triggers)).then(({status, data}) => {
                    if (status === 200 && !data.error) {
                        this.$emit("campaignTriggersSaved")
                    }
                })
            }
        },
        getHours(minString) {
            if (this.isNull(minString)) {
                minString = "-1"
            }
            let minInt = parseInt(minString) + 1

            let hours = []

            for (let i = minInt; i < 24; i++) {
                hours.push(i.toString());
            }

            return hours
        },
    },
}
</script>
<style scoped>
.custom-input >>> .v-input__slot {
    margin: 0 !important;
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}

.custom-input >>> .v-input__slot::after {
    border: none !important;
}

.custom-input >>> .v-input__slot {
    border: none !important;
}

.number-input >>> input::-webkit-outer-spin-button,
.number-input >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-input >>> input[type=number] {
    -moz-appearance: textfield;
}

.custom-select {
    min-width: 276px;
}
.v-input--selection-controls {
    margin-top: 0px;
}
</style>
