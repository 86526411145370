<template>
    <div class="add-campaign">
        <v-form ref="campaignForm" v-model="valid" class="d-flex flex-column" lazy-validation>
            <div class="d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("infoFormNameLabel") }}</p>
                <v-text-field v-model="name" :rules="[rules.notEmpty]" class="custom-input rounded-lg font-weight-bold text-body-2" dense filled required></v-text-field>
            </div>

            <div class="d-flex flex-column align-start input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("triggersFormUsageLabel") }}</p>
                <div class="d-flex align-center">
                    <v-select
                        v-model="usage"
                        :items="$t('triggersFormUsage')"
                        :rules="[rules.notEmpty]"
                        class="rounded-lg custom-input custom-select"
                        dense
                        filled
                        item-text="value"
                        item-value="key"
                        required
                    >
                    </v-select>
                </div>
            </div>

            <div class="d-flex align-center input-group">
                <label class="mr-4 darkGrey--text text-body-2 font-weight-medium">{{ $t("infoFormActiveLabel") }}</label>
                <v-switch v-model="isActive"></v-switch>
            </div>

            <CustomButton v-if="isNull(campaign)" class="align-self-end" @click="createCampaign">{{ $t("infoFormCreateBtnLabel") }}</CustomButton>
            <CustomButton v-else class="align-self-end" @click="updateCampaign">{{ $t("infoFormUpdateBtnLabel") }}</CustomButton>
        </v-form>
    </div>
</template>

<script>
const CampaignService = require("@/services/CampaignService");
export default {
    name: 'AddCampaign',
    props: ['campaign'],
    data: (vm) => {
        return {
            valid: true,
            name: null,
            isActive: true,
            usage: [],
            rules: {
                notEmpty: value => vm.isNotNull(value) ? true : vm.$t('emptyFieldError')
            },
        }
    },
    created() {
        this.name = this.campaign?.name || null
        this.isActive = this.campaign?.active || false
        this.usage = this.campaign?.campaignUsage || null
    },
    methods: {
        createCampaign() {
            console.log(this.$refs.campaignForm.validate())
            if (this.$refs.campaignForm.validate()) {
                CampaignService.create(this.name, this.isActive, this.usage).then(({status, data}) => {
                    if (status === 200 && !data.error) {
                        this.$emit("campaignInfoSaved", parseInt(data.campaignId))
                    }
                })
                // this.$emit("campaignInfoSaved")
            }
        },
        updateCampaign() {
            if (this.$refs.campaignForm.validate()) {
                CampaignService.update(this.campaign.id, this.name, this.isActive, this.usage).then(({status, data}) => {
                    if (status === 200 && !data.error) {
                        this.$emit("campaignInfoSaved")
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.custom-input >>> .v-input__slot {
    margin: 0 !important;
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}

.custom-input >>> .v-input__slot::after {
    border: none !important;
}

.custom-input >>> .v-input__slot {
    border: none !important;
}
.custom-select {
    min-width: 276px;
}
</style>
