const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function create(name, isActive, usage){
    let path = "/campaign/add"

    let params = new URLSearchParams();
    params.append("name", name);
    params.append("isActive", isActive);
    params.append("usage", usage);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function manageTriggers(campaignId, triggers){
    let path = "/campaign/triggers/manage"

    let params = new URLSearchParams();
    params.append("campaignId", campaignId);
    params.append("triggers", triggers);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function getCampaignById(id){
    let path = "/campaign/get/by/id?campaignId=" + id

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}

export function getAllCampaigns(){
    let path = "/campaign/get/all"

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}

export function update(campaignId, name, isActive, usage){
    let path = "/campaign/update/by/id"

    let params = new URLSearchParams();
    params.append("campaignId", campaignId);
    params.append("name", name);
    params.append("isActive", isActive);
    params.append("usage", usage);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function setScenario(campaignId, scenarioId){
    let path = "/campaign/set/scenario"

    let params = new URLSearchParams();
    params.append("campaignId", campaignId);
    params.append("scenarioId", scenarioId);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function remove(campaignId){
    let path = "/campaign/remove/by/id"

    let params = new URLSearchParams();
    params.append("campaignId", campaignId);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function getCampaignsByScenario(scenarioId){
    let path = `/campaign/get/by/scenario?scenarioId=${scenarioId}`

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}
